<template>
  <div>
    <topnav></topnav>
    <div class="land-page">
      <div class="land-box">
        <div class="land-box-inner">
          <h1 class="land-page-head">
            Yeshiva of Alumni Dear,
            <br />
            Torah Darchei
          </h1>
          <p class="land-mid">
            It's with great privilege that we invite you to a get together at
            Rav Yosef Hartstein's home
          </p>
          <p class="land-big">
            20 Hekel Road, Regency
            <br />
            Tuesday, May 16 at 9:00 pm
          </p>
          <div class="land-hor-line"></div>

          <p>Featuring special speaker,</p>
          <p class="land-funcy">HaRav Dovid Geller, Shlita</p>

          <p>Mashgiach Yeshivas Darchei Torah</p>
          <div class="land-hor-line"></div>

          <p>Looking forward to greeting you,</p>
          <p class="land-end-txt">
            <b>Meir Oratz</b>
            <span class="land-line">|</span>
            Rosh Mosdos LAMDEINI
            <span class="land-line">|</span>
            Yerushalayim, Ir HaKodesh
          </p>
          <router-link to="/donate" class="land-btn">Donate</router-link>
          <!-- <div class="land-form-wrap">
          <form
            @submit.prevent="submitForm"
            v-if="formStatus != 'success'"
            class="land-form"
          >
                      <p>Fill the form to submit a Donation</p>

              <input
                placeholder="Your Name (required)"
                type="text"
                name="name"
                class="land-field"
                required=""
                v-model="form.fullName"
              />
              <input
                placeholder="Amount in USD"
                type="number"
                name="Amount"
                class="land-field"
                required=""
                v-model="form.amount"
              />
              <input
                v-model="form.phone"
                placeholder="Your Phone (required)"
                type="tel"
                name="phone"
                required=""
                class="land-field"
              />

              <textarea v-model="form.message" placeholder="Your Message" class="land-field big"></textarea>

              <input type="submit" value="Submit" class="land-btn" />
          </form>

          <div class="form-fail" v-if="formStatus == 'err'">
            <p>Oops! Something went wrong while submitting the form.</p>
          </div>
          <div class="form-success" v-if="formStatus === 'success'">
            <p>
              Thank you!
              <br />
              Your submission has been received!
            </p>
          </div>
        </div> -->
        </div>
      </div>
      <div class="land-bg"/>
    </div>
  </div>
</template>

<script>
import Topnav from "../components/Topnav.vue";

export default {
  components: { Topnav },
  data() {
    return {
      popupactive: false,
      form: {
        fullName: "",
        amount: "",
        phone: "",
        message: "",
        page:"lakewood",
      },
      formStatus: "",
    };
  },
  methods: {
    async submitForm() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        await this.$db.collection("donation").post(this.form);
        this.formStatus = "success";
        this.submitting = false;
      } catch (err) {
        this.formStatus = "err";
        this.submitting = false;
      }
    },
  },
  name: "ParlotMeeting",
};
</script>

